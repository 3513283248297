export default {
    path: '/from',
    name: 'from',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/from/from_product',
    children: [
        {
            path: 'from_product',
            meta: { title: '追溯产品管理', permission: 'from_product' },
            component: () => import('@/views/from/fromProduct/index'),
        },
        {
            path: 'from_result',
            meta: { title: '半成品及成品管理', permission: 'from_result' },
            component: () => import('@/views/from/fromResult/index'),
        }
    ],
}
