import VueRouter from 'vue-router'
import user from './user'
import account from './account'
import manage from './manage'
import system from './system'
import report from './report'
import enterprise from './enterprise'
import inspect from './inspect'
import from from './from'
import basicData from './basicData'
import businessData from './businessData'
import dispatch from './dispatch'
import refrigerate from './refrigerate'
import produce from './produce'
import goods from './goods'
import control from './control'
import iot from './iot'
import intelligence from './intelligence'
import purchasing from './purchasing'
import sale from './sale'
import warehouse from './warehouse'
import finance from './finance'

const index = {
  path: '/',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/home',
  children: [
    {
      path: '/home',
      name: 'home',
      meta: { title: '首页' },
      component: () => import('@/views/home/Home'),
    },
  ]
}

const routes = [index, user, account, iot, enterprise, control, manage, from, inspect, intelligence, refrigerate, produce, system, report, basicData, businessData, dispatch, goods, purchasing, sale, warehouse, finance];

export default new VueRouter({ mode: 'hash', routes })
