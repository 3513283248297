export default {
    path: '/produce',
    name: 'produce',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/produce/outbound',
    children: [
        {
            path: 'outbound',
            meta: { title: '原辅料使用记录', permission: 'outbound' },
            component: () => import('@/views/produce/outbound/index'),
        },
        {
            path: 'outboundDetail',
            meta: { title: '原辅料使用详情', permission: 'outboundDetail' },
            component: () => import('@/views/produce/outboundDetail/index'),
        },
        {
            path: 'formulas',
            meta: { title: '配方及投料记录', permission: 'formulas' },
            component: () => import('@/views/produce/formula/index'),
        },
        {
            path: 'storage',
            meta: { title: '成品入库创建', permission: 'storage' },
            component: () => import('@/views/produce/storage/index'),
        },
        {
            path: 'purchase_record',
            meta: { title: '成品入库列表', permission: 'purchase_record' },
            component: () => import('@/views/produce/purchaseRecord/index'),
        },
        {
            path: 'food_additives',
            meta: { title: '食品添加剂使用记录', permission: 'food_additives' },
            component: () => import('@/views/produce/additives/index'),
        },
        {
            path: 'process_control',
            meta: { title: '生产加工过程控制', permission: 'process_control' },
            component: () => import('@/views/produce/control/index'),
        },
        {
            path: 'technology',
            meta: { title: '生产工艺参数', permission: 'technology' },
            component: () => import('@/views/produce/technology/index'),
        },
        {
            path: 'device_manage',
            meta: { title: '设备管理', permission: 'device_manage' },
            component: () => import('@/views/produce/device/index'),
        }
    ],
}
