export default {
    path: '/inspect',
    name: 'inspect',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/inspect/record',
    children: [
        {
            path: 'record',
            meta: { title: '留存样品留样记录', permission: 'record' },
            component: () => import('@/views/inspect/record/index'),
        },
        {
            path: 'report',
            meta: { title: '检验报告', permission: 'report' },
            component: () => import('@/views/inspect/report/index'),
        }
    ],
}
