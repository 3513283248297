export default {
    path: '/IOT',
    name: 'IOT',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/IOT/establish_mechanism',
    children: [
        {
            path: 'establish_mechanism',
            meta: { title: '建立过程机制', permission: 'establish_mechanism' },
            component: () => import('@/views/iot/establishMechanism/index'),
        },
        {
            path: 'inspection_cycle',
            meta: { title: '检查及审核周期', permission: 'inspection_cycle' },
            component: () => import('@/views/iot/inspectionCycle/index'),
        },
        {
            path: 'corrective_action',
            meta: { title: '纠正措施管理', permission: 'corrective_action' },
            component: () => import('@/views/iot/correctiveAction/index'),
        }
    ],
}
