export default {
    path: '/IOT',
    name: 'IOT',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/IOT/intelligence',
    children: [
        {
            path: 'intelligence',
            meta: { title: '智能化管理', permission: 'intelligence' },
            component: () => import('@/views/intelligence/intelligence/index'),
        }
    ],
}
