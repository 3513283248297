export default {
    path: '/refrigerate',
    name: 'refrigerate',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/refrigerate/keep',
    children: [
        {
            path: 'keep',
            meta: { title: '贮存管理', permission: 'keep' },
            component: () => import('@/views/refrigerate/keep/index'),
        },
        {
            path: 'monitors',
            meta: { title: '定期监测记录', permission: 'monitors' },
            component: () => import('@/views/refrigerate/monitor/index'),
        },
        {
            path: 'transports',
            meta: { title: '运输过程记录', permission: 'transports' },
            component: () => import('@/views/refrigerate/transport/index'),
        }
    ],
}
