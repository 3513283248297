export default {
    path: '/control',
    name: 'control',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/control/daily_control',
    children: [
        {
            path: 'daily_control',
            meta: { title: '日管控', permission: 'daily_control' },
            component: () => import('@/views/control/dailyControl/index'),
        },
        {
            path: 'weekly_control',
            meta: { title: '周排查', permission: 'weekly_control' },
            component: () => import('@/views/control/weeklyControl/index'),
        },
        {
            path: 'monthly_control',
            meta: { title: '月调度', permission: 'monthly_control' },
            component: () => import('@/views/control/monthlyControl/index'),
        }
    ],
}
