export default {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/enterprise/enterpriseInfo',
    children: [
        {
            path: 'enterpriseInfo',
            meta: { title: '企业信息', permission: 'enterpriseInfo' },
            component: () => import('@/views/enterprise/enterpriseInfo/index'),
        },
        {
            path: 'employeeView',
            meta: { title: '从业人员', permission: 'employeeView' },
            component: () => import('@/views/enterprise/employeeView/index'),
        },
        {
            path: 'system',
            meta: { title: '制度管理', permission: 'system' },
            component: () => import('@/views/enterprise/system/index'),
        }
    ],
}
