export default {
    path: '/dispatch',
    name: 'dispatch',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/dispatch/call_back',
    children: [
        {
            path: 'call_back',
            meta: { title: '召回管理', permission: 'call_back' },
            component: () => import('@/views/dispatch/callBack/index'),
        },
        {
            path: 'call_handle',
            meta: { title: '召回产品处理', permission: 'call_handle' },
            component: () => import('@/views/dispatch/callHandle/index'),
        },
        {
            path: 'unqualified',
            meta: { title: '不合格品管理', permission: 'unqualified' },
            component: () => import('@/views/dispatch/unqualified/index'),
        },
        {
            path: 'accidents',
            meta: { title: '食品安全事故管理', permission: 'accidents' },
            component: () => import('@/views/dispatch/accidents/index'),
        }
    ],
}
